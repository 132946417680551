<template>
    <div>
        <v-container class="paper-border">
            <v-row no-gutters>
                <v-col cols="8" class="d-flex flex-column mt-0">
                    <span class="font-weight-bold">{{ $t('message.seller') }}</span>
                    <span>{{ preview.supplier }}</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column mt-0">
                    <span class="font-weight-bold">{{ $t('message.date') }}</span>
                    <span>{{ preview.date }}</span>
                </v-col>
                <v-col cols="2" class="d-flex flex-column text-right mt-0">
                    <span class="font-weight-bold">{{ $t('message.number') }}</span>
                    <span>{{ preview.number }}</span>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="d-flex flex-column mt-0">
                    <span class="font-weight-bold">{{ $t('message.buyer') }}</span>
                    <span>{{ preview.buyer }}</span>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="d-flex flex-column mt-0">
                    <span class="font-weight-bold">{{ $t('message.termsOfPayment') }}</span>
                    <span>{{ preview.supplierTerms }}</span>
                </v-col>
                <v-col cols="6" class="d-flex flex-column text-right mt-0">
                    <span class="font-weight-bold">{{ $t('message.shipment') }}</span>
                    <span>{{ preview.etd }}</span>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="d-flex flex-column text-right mt-0 offset-6">
                    <span class="font-weight-bold">{{ $t('message.countryOfOrigin') }}</span>
                    <span>{{ [preview.origin].filter(Boolean).join(' / ') }}</span>
                </v-col>
            </v-row>
            <v-row no-gutters>
                <v-col cols="6" class="d-flex flex-column text-right mt-0 offset-6">
                    <span class="font-weight-bold">{{ $t('message.termsOfDelivery') }}</span>
                    <span>{{ preview.incoterm }}</span>
                </v-col>
            </v-row>
            <v-simple-table
                class="pt-2"
                dense
            >
                <template v-slot:default>
                    <thead>
                        <tr>
                            <th class="px-0 font-sm border-bottom-2">{{ $t('message.specification') }}</th>
                            <th class="px-0 font-sm border-bottom-2">{{ $t('message.size') }}</th>
                            <th class="px-0 font-sm border-bottom-2">{{ $t('message.grade') }}</th>
                            <th class="px-0 font-sm text-right width-8-pct border-bottom-2">{{ $t('message.quantity') }}</th>
                            <th class="px-0 font-sm text-right width-8-pct border-bottom-2">{{ $t('message.unitPrice') }}</th>
                            <th class="px-0 font-sm text-right width-8-pct border-bottom-2">{{ $t('message.amount') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <template v-for="(item,index) in EprItems">
                            <tr v-if="index == 0">
                                <td colspan="6" class="px-0 border-0">{{ getUiDescription(item) }}</td>
                            </tr>
                            <tr v-else-if="index > 0 && getUiDescription(EprItems[index - 1]) != getUiDescription(item)">
                                <td colspan="6" class="px-0 border-0">{{ getUiDescription(item) }}</td>
                            </tr>
                            <tr>
                                <td class="px-0 border-0"></td>
                                <td class="px-0 border-0">{{ item.Size.title != null ? item.Size.title : $t('message.seeRemarks') }}</td>
                                <td class="px-0 border-0">{{ item.Grade.title != null  ? item.Grade.title : $t('message.seeRemarks') }}</td>
                                <td class="px-0 text-right border-0">{{ formatThisNumber(item.EprItem.itemqty, '0,0.00') + ' ' + uofmQty.find((uofm)=>uofm.Measurement.id == item.EprItem.measurement_id).Measurement.title }}</td>
                                <td class="px-0 text-right border-0">{{ formatThisNumber(item.EprItem.costprice, '0,0.00') }}</td>
                                <td class="px-0 text-right border-0">{{ formatThisNumber(item.EprItem.cost, '0,0.00') }}</td>
                            </tr>
                        </template>
                        <tr>
                            <td colspan="6" class="px-0 pt-10 border-0">
                                <div style="white-space: pre-line" v-if="preview.remarks != null">
                                    <div v-if="preview.remarks.indexOf('Remark') == -1">{{ $t('message.remarks') }}</div>
                                    <div>{{ preview.remarks.trim() }}</div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td class="px-0 border-bottom-2 px-0 pt-3">
                                <div class="d-flex flex-column" v-if="[2,3,4,5,6].includes(preview.fsc)">
                                    <span>{{ $t('message.certCode') + ' : ' + getCertification(preview.fsc,'cert_no') }}</span>
                                    <span>{{ $t('message.expiryDate') + ' : ' + getCertification(preview.fsc,'cert_expiry_date') }}</span>
                                </div>
                            </td>
                            <td class="border-bottom-2 pt-3"></td>
                            <td class="border-bottom-2 pt-3">{{ $t('message.total') + ' ' + preview.containers }}</td>
                            <td class="border-bottom-2 text-right px-0 pt-3">
                                <span v-for="(total,index) in itemsTotalQty">
                                    {{ formatThisNumber(total.Total.qty, uofmPrecision(total.Total.uofm)) + ' '+ total.Total.uofm }}
                                </span>
                            </td>
                            <td class="border-bottom-2 pt-3"></td>
                            <td class="border-bottom-2 text-right px-0 pt-3">{{ formatThisNumber(itemsTotalCost, '0,0.00') }}</td>
                        </tr>
                    </tbody>
                </template>
            </v-simple-table>
            <v-row no-gutters v-if="false">
                <v-col cols="4">{{ $t('message.remarks') }}:</v-col>
                <v-col cols="8"><div v-html="preview.remarks"></div></v-col>
            </v-row>
        </v-container>
    </div>
</template>

<script>
import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapGetters} from "vuex";
import {formatDate, numberFormat} from "../../helpers/helpers";

export default {
    name: "ExportPurchaseRequestPreview",
    data(){
        return {
            preview: {
                buyer: null,
                buyerTerms: null,
                certification: null,
                container: null,
                currency: 'USD',
                date: null,
                destination: '',
                eta: null,
                etd: null,
                fsc: null,
                incoterm: null,
                isExport: false,
                number: '',
                office: null,
                origin: '',
                pol: null,
                pod: null,
                remarks: null,
                sales: null,
                supplier: null,
                supplierTerms: null,
                type: null
            }
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('epr',{
            Epr__containercount: 'Epr.containercount',
            Epr__containersize_id: 'Epr.containersize_id',
            Epr__remarks: 'Epr.remarks',
            Epr__currency_id: 'Epr.currency_id',
            Epr__customer_id: 'Epr.customer_id',
            Epr__destination_id: 'Epr.destination_id',
            Epr__etadate: 'Epr.etadate',
            Epr__etddate: 'Epr.etddate',
            // Epr__fsc: 'Epr.fsc',
            Epr__incoterm_id: 'Epr.incoterm_id',
            Epr__is_export: 'Epr.is_export',
            Epr__loadingport_id: 'Epr.loadingport_id',
            Epr__eprdate: 'Epr.eprdate',
            Epr__office_id: 'Epr.office_id',
            Epr__paymentstatement_id: 'Epr.paymentstatement_id',
            Epr__popaymentstatement_id: 'Epr.popaymentstatement_id',
            Epr__productorigin_id: 'Epr.productorigin_id',
            Epr__salescontact_id: 'Epr.salescontact_id',
            Epr__salestype_id: 'Epr.salestype_id',
            Epr__shippingport_id: 'Epr.shippingport_id',
            Epr__shippingweekstatement: 'Epr.shippingweekstatement',
            Epr__supplier_id: 'Epr.supplier_id',
            Epr__title: 'Epr.title',
        },'statePrefix'),
        ...mapMultiRowFieldsPrefixed('epritem', {
            EprItems: 'EprItems',
        },'statePrefix'),
        ...mapFieldsPrefixed('epritem',{
            itemsTotalAmount: 'itemsTotalAmount',
            itemsTotalCost: 'itemsTotalCost',
            itemsTotalQty: 'itemsTotalQty'
        },'statePrefix'),
        ...mapGetters('buyer', {
            allBuyers: 'allBuyers',
        }),
        ...mapGetters('certification',{
            allCertifications: 'allCertifications'
        }),
        ...mapGetters('country',{
            allCountries: 'allCountries'
        }),
        ...mapGetters('grades', {
            allGrades: 'allGrades'
        }),
        ...mapGetters('paymentterm',{
            allBuyerPaymentTerms: 'allBuyerPaymentTerms',
            allSupplierPaymentTerms: 'allSupplierPaymentTerms',
        }),
        ...mapGetters('productgroup',{
            allProductGroups: 'allProductGroups'
        }),
        ...mapGetters('salescolleague',{
            allSalesColleagues: 'allSalesColleagues'
        }),
        ...mapGetters('shippingport', {
            allShippingDischargePorts: 'allShippingDischargePorts',
            allShippingLoadingPorts: 'allShippingLoadingPorts'
        }),
        ...mapGetters('species', {
            allSpecies: 'allSpecies'
        }),
        ...mapGetters('supplier', {
            allSuppliers: 'allSuppliers'
        }),
        ...mapGetters([
            'certifications',
            // 'certificationsNew',
            'containerSizes',
            'currencies',
            'incoterms',
            'offices',
            // 'productGroupsNew',
            'salesTypes',
            'uofmQty'
        ]),
        addMode: function () {
            return this.$route.matched.some(({name}) => name === 'add_epr')
        },
        currencyFormatPrecision () {
            return this.currencies.find((currency) => currency.Currency.id === this.Epr__currency_id)?.Currency?.formatPrecision
        },
        Epr__fsc() {
            let fscId  = null
            if(this.EprItems.length > 0){
                this.EprItems.forEach(item => {
                    if(item.EprItem.certification_id != null && [0,7,8].includes(item.EprItem.certification_id) == false){
                        fscId = item.EprItem.certification_id
                    }
                })
            }
            return fscId
        },
        updateMode: function () {
            return this.$route.matched.some(({name}) => name === 'update_epr')
        }
    },
    methods: {
        formatDate,
        formatThisNumber(value,format){
            return numberFormat(value,format)
        },
        getCertification(certificationId, certificationInfo) {
            let certificationObj = this.allCertifications.find(c => c.Certification.id == certificationId)
            if(certificationObj){
                return certificationObj?.Certification[certificationInfo]
            }
            return ''
        },
        getProductGroup(productGroupId) {
            let productObj = this.allProductGroups.find(p => p.ProductGroup.id == productGroupId)
            if(productObj){
                return productObj.ProductGroup.title + ', '
            }
            return ''
        },
        getUiDescription(item) {
            let uiDescription = []

            let species = this.allSpecies.find(s => s.Species.id == item.EprItem.species_id)
            if(species) uiDescription.push(species.Species.title)

            if(item.EprItem.description != null) {
                let product = this.allProductGroups.find(p => p.ProductGroup.id == item.EprItem.productgroup_id)
                if (product && item.EprItem.description.includes(product.ProductGroup.title) == false) uiDescription.push(product.ProductGroup.title)
            } else {
                let product = this.allProductGroups.find(p => p.ProductGroup.id == item.EprItem.productgroup_id)
                if (product) uiDescription.push(product.ProductGroup.title)
            }

            if(item.EprItem.description != '') uiDescription.push(item.EprItem.description)

            let certification = this.allCertifications.find(c => c.Certification.id == item.EprItem.certification_id)
            if(certification && item.EprItem.description != null && item.EprItem.description.includes(certification.Certification.title) == false && certification.Certification.title != 'None') uiDescription.push(certification.Certification.title)
            if(certification && item.EprItem.description == null && certification.Certification.title != 'None') uiDescription.push(certification.Certification.title)

            uiDescription = uiDescription.join(', ')
            return uiDescription
        },
        uofmPrecision (uofm) {
            if(uofm) {
                if(isNaN(uofm)) {
                    if(uofm.trim() == 'M3'){
                        return '0,0.000'
                    } else {
                        return this.uofmQty.find((u) => u.Measurement.title == uofm.trim())?.Measurement?.formatPrecision
                    }
                } else {
                    if(uofm == 1){
                        return '0,0.000'
                    } else {
                        return this.uofmQty.find((u) => u.Measurement.id == uofm)?.Measurement?.formatPrecision
                    }
                }
            } else {
                return '0,0.00'
            }
        },
    },
    created(){
        if(this.Epr__containercount != null){
            let containerSize = this.containerSizes.find(c => c.Containersize.id == this.Epr__containersize_id)
            if([1,3,5].includes(this.Epr__containersize_id)){
                this.preview.containers = this.Epr__containercount + " X " + containerSize?.Containersize.title
            } else {
                this.preview.containers = containerSize?.Containersize.title
            }
        }
        if(this.Epr__customer_id != null){
            this.preview.buyer = this.allBuyers.find(c => c.Customer.id == this.Epr__customer_id)?.Customer.otsname
        }
        if(this.Epr__currency_id != null){
            this.preview.currency = this.currencies.find(c => c.Currency.id == this.Epr__currency_id)?.Currency.code
        }
        if(this.Epr__destination_id != null){
            this.preview.destination = this.allCountries.find(c => c.Country.id ==  this.Epr__destination_id)?.Country.name
        }
        if(this.Epr__etadate != null){
            this.preview.eta = formatDate(this.Epr__etadate)
        }
        if(this.Epr__etddate != null){
            if(this.Epr__shippingweekstatement && this.Epr__shippingweekstatement.length > 0){
                this.preview.etd = this.Epr__shippingweekstatement
            } else {
                this.preview.etd = formatDate(this.Epr__etddate)
            }
        }
        if(this.Epr__fsc != null){
            this.preview.certification = this.certifications.find(c => c.Certification.id == this.Epr__fsc)?.Certification.title
            this.preview.fsc = this.Epr__fsc
        }
        if(this.Epr__incoterm_id != null){

            this.preview.incoterm = this.incoterms.find(i => i.Incoterm.id ==  this.Epr__incoterm_id)?.Incoterm.title

            let incoterm = this.incoterms.find((incoterm)=>incoterm.Incoterm.id == this.Epr__incoterm_id)?.Incoterm?.title;
            let incotermport = '';
            switch(this.Epr__incoterm_id){
                case null:
                    incotermport = ''
                    break;
                case 1:
                case 4:
                case 6:
                    if(this.Epr__loadingport_id != null) {
                        let loadingPort = this.allShippingLoadingPorts.find(s => s.Shippingport.id ==  this.Epr__loadingport_id)
                        incotermport = ' ' + loadingPort.Shippingport.title
                    }
                    break
                case 2:
                case 3:
                case 7:
                    if(this.Epr__shippingport_id != null) {
                        let shippingPort = this.allShippingDischargePorts.find(s => s.Shippingport.id ==  this.Epr__shippingport_id)
                        incotermport = ' ' + shippingPort.Shippingport.title
                    }
                    break;
                default:
                    incotermport = ''
            }
            this.preview.incoterm = incoterm + incotermport;
        }
        if(this.Epr__is_export != null){
            this.preview.isExport = this.Epr__is_export == 1 ? true : false
        }
        if(this.Epr__loadingport_id != null){
            this.preview.pol = this.allShippingLoadingPorts.find(s => s.Shippingport.id ==  this.Epr__loadingport_id)?.Shippingport.title
        }
        if(this.Epr__eprdate != null){
            this.preview.date = formatDate(this.Epr__eprdate)
        }
        if(this.Epr__office_id != null){
            this.preview.office = this.offices.find(o => o.Office.id ==  this.Epr__office_id)?.Office.title
        }
        if(this.Epr__paymentstatement_id != null){
            this.preview.buyerTerms = this.allBuyerPaymentTerms.find(c => c.Paymentterm.id ==  this.Epr__paymentstatement_id)?.Paymentterm.title
        }
        if(this.Epr__popaymentstatement_id != null){
            this.preview.supplierTerms = this.allSupplierPaymentTerms.find(c => c.Paymentterm.id ==  this.Epr__popaymentstatement_id)?.Paymentterm.title
        }
        if(this.Epr__productorigin_id != null){
            this.preview.origin = this.allCountries.find(c => c.Country.id ==  this.Epr__productorigin_id)?.Country.name
        }
        if(this.Epr__remarks != null){
            this.preview.remarks = this.Epr__remarks
        }
        if(this.Epr__salescontact_id != null){
            this.preview.sales = this.allSalesColleagues.find(s => s.Salesperson.id == this.Epr__salescontact_id)?.Salesperson.name
        }
        if(this.Epr__salestype_id != null){
            this.preview.type = this.salesTypes.find(s => s.Salestype.id == this.Epr__salestype_id)?.Salestype.code
        }
        if(this.Epr__shippingport_id != null){
            this.preview.pod = this.allShippingDischargePorts.find(s => s.Shippingport.id ==  this.Epr__shippingport_id)?.Shippingport.title
        }
        if(this.Epr__supplier_id != null){
            this.preview.supplier = this.allSuppliers.find(s => s.Supplier.id == this.Epr__supplier_id)?.Supplier.otsname
        }
        if(this.Epr__title != null){
            this.preview.number = this.Epr__title
        }
    }
}
</script>

<style>
.paper-border {
    border: dashed 1px darkgrey;
}
.border-top-2 {
    border-top: 2px solid black;
}
.border-bottom-2 {
    border-bottom: 2px solid black !important;
}
.v-data-table tbody td {
    vertical-align: middle !important;
    height: 25px !important;
}
</style>